<template>
  <v-container
    class="background"
    fluid
    fill-height
  >
    <default-modal
      v-if="showModal"
      :message="modalState.message"
      :action="modalState.action"
      @close="closeModal"
      @action="doAction"
    />
    <v-row class="left-right-container">
      <v-col class="left-area ma-4">
        <map-component
          mode="wall"
        />
      </v-col>
      <v-col class="right-area ma-4">
        <v-row
          class="header"
          justify="space-around"
        >
          <img
            src="@/assets/back.png"
            alt="뒤로가기"
            type="button"
            @click="$router.replace('/location-info')"
          >
          <span
            class="wall-title"
          > &nbsp;&nbsp;가상벽 </span>
          <v-spacer />
        </v-row>
        <v-row class="content-area">
          <template v-if="isMakingWall">
            <v-col
              class="btn-container"
              cols="12"
              align-self="center"
            >
              <v-btn
                class="btn-add"
                color="#298079"
                dark
                @click="reDrawWall"
              >
                다시 그리기
              </v-btn>
              <v-row class="btn-adding-row-container ma-0 pa-0">
                <v-col class="btn-adding-col-container ma-0 py-0">
                  <v-btn
                    class="btn-adding mt-8 mr-4"
                    color="#7c7c7c"
                    dark
                    @click="cancelWall"
                  >
                    취소
                  </v-btn>
                  <template v-if="canBeSaved">
                    <v-btn
                      class="btn-adding mt-8 ml-4"
                      color="#00BFB4"
                      dark
                      @click="saveWall"
                    >
                      완료
                    </v-btn>
                  </template>
                  <template v-else>
                    <v-btn
                      class="btn-adding mt-8 ml-4"
                      color="#00BFB4"
                      dark
                      disabled
                    >
                      완료
                    </v-btn>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </template>
          <template v-else>
            <v-col
              class="btn-container"
              cols="12"
              align-self="center"
            >
              <v-btn
                class="btn-add"
                color="#00BFB4"
                dark
                @click="addWall"
              >
                추가
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </v-col>
      <default-header/>
    </v-row>
  </v-container>
</template>

<script>
import MapComponent from '@/components/map/map.vue';
import EventBus from '@/main.js';
import DefaultModal from '../../components/modals/defaultModal.vue';
import DefaultHeader from '@/components/header/defaultHeader.vue';

export default {
  name: 'Wall',
  components: {
    MapComponent,
    DefaultModal,
    DefaultHeader,
  },
  data() {
    return {
      isMakingWall: false,
      canBeSaved: false,
      showModal: false,
      modalState: {
        message: null,
        action: null
      },
    };
  },
  created() {
    EventBus.$on('reDrawWall', () => {
      this.canBeSaved = true;
    });
    EventBus.$on('showRemoveObstacleModal', this.confirmlDeleteWall);
  },
  beforeDestroy() {
    EventBus.$off('reDrawWall');
    EventBus.$off('showRemoveObstacleModal');
  },
  methods: {
    addWall() {
      EventBus.$emit('addWall');
      this.isMakingWall = true;
    },
    cancelWall() {
      EventBus.$emit('cancelAddWall');
      this.isMakingWall = false;
      this.canBeSaved = false;
    },
    reDrawWall() {
      EventBus.$emit('cancelAddWall');
      EventBus.$emit('addWall');
      this.canBeSaved = false;
    },
    saveWall() {
      EventBus.$emit('saveWall');
      this.isMakingWall = false;
      this.canBeSaved = false;
    },
    confirmlDeleteWall() {
      this.modalState.message = '가상벽을 삭제하시겠습니까?';
      this.modalState.action = '삭제';
      this.showModal = true;
    },
    doAction() {
      EventBus.$emit('deleteWall');
      this.closeModal();
    },
    closeModal() {
      this.modalState.message = null;
      this.modalState.action = null;
      this.showModal = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.background {
  overflow: hidden;
  background-color: #393939;
  word-break: keep-all;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
}

.background > .left-right-container {
  height: 100vh;
}

.wall-title {
  color: #ffffff;
  font-size: 80px !important;
}

.content-area {
  height: 90%;
}

.btn-container {
  height: 50% ;
  text-align: center;
}

.btn-add {
  height: 30% !important;
  width: 70%;
  border-radius: 10px;
  font-size: 4rem !important;
  line-height: 117px;
  text-align: center;
}

.btn-adding-row-container {
  height: 30% ;
}

.btn-adding-col-container {
  height: 100% ;
  width: 70%;
}

.btn-adding {
  height: 100% !important;
  width: 34%;
  border-radius: 10px;
  font-size: 4rem !important;
  line-height: 117px;
  text-align: center;
}

@media (max-width: 1024px) {
  .header img {
    width: 30px;
    height: 30px;
  }

  .wall-title {
    font-size: 20px !important;
  }

  .btn-add {
    height: 40px !important;
    width: 100% !important;
    border-radius: 10px;
    font-size: 2rem !important;
  }

  .btn-adding-row-container {
    height: 40px ;
    width: 100%;
  }

  .btn-adding-col-container {
    padding: 0 !important;
    width: 70%;
  }

  .btn-adding {
    height: 100% !important;
    width: 45%;
    border-radius: 10px;
    font-size: 2rem !important;
  }
}

@media (max-width: 1075px) {
  .btn-add {
    width: 95%;
  }

  .btn-adding {
    margin: 28px 0 0 !important;
    height: 100% !important;
    width: 100%;
    font-size: 2rem !important;
  }
}

</style>
